<template>
  <div class="about-us-page">
    <page-header
      :cover="require('../assets/images/about-us-cover.png')"
      :title="$t('aboutDesc')"
      :desc="$t('introDesc') + ' ' + $t('introLongDesc')"
    />
    <div class="container">
      <div class="row boxs my-4 px-3 text-center text-md-start">
        <div class="col-md-4 my-1 mx-auto box">
          <div class="icon mx-auto mx-md-0">
            <img src="../assets/images/shield.png" alt="icon" />
          </div>
          <div class="title">{{ $t("safeAndSecured") }}</div>
          <div class="desc">{{ $t("safeAndSecuredDesc") }}</div>
        </div>
        <div class="col-md-4 my-1 mx-auto box">
          <div class="icon mx-auto mx-md-0">
            <img src="../assets/images/rating.png" alt="icon" />
          </div>
          <div class="title">{{ $t("highlyExpertTeam") }}</div>
          <div class="desc">{{ $t("highlyExpertTeamDesc") }}</div>
        </div>
        <div class="col-md-4 my-1 mx-auto box">
          <div class="icon mx-auto mx-md-0">
            <img src="../assets/images/shield.png" alt="icon" />
          </div>
          <div class="title">{{ $t("ourMagicalFormula") }}</div>
          <div class="desc">{{ $t("ourMagicalFormulaDesc") }}</div>
        </div>
      </div>
    </div>
    <Counts />
    <div class="services">
      <div class="container text-center">
        <div class="sec-title">{{ $t("ourServices") }}</div>
        <div class="sec-desc w-lg-50 mx-auto">
          {{ $t("ourServicesDesc") }}
        </div>
        <div class="serv-cards">
          <template v-for="(service, serind) in services">
            <Service-card
              :key="serind"
              :service="service"
              @openService="toggelModal"
              v-if="service.title != $t('dataanalysis')"
            />
          </template>
        </div>
      </div>
    </div>
    <Parteners />
    <!-- <Testimonials /> -->
    <!-- <Our-team class="my-5" /> -->
    <!-- <Let-us-help /> -->
    <Contact-us />
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <Modal
        :service="modalData"
        :link="link"
        :btnName="btnName"
        v-if="activeModal"
        @closeModal="toggelModal"
      />
    </transition>
  </div>
</template>

<script>
import ContactUs from "../components/Layout/ContactUs.vue";
import Counts from "../components/Layout/Counts.vue";
// import LetUsHelp from "../components/Layout/LetUsHelp.vue";
// import OurTeam from "../components/Layout/OurTeam.vue";
import Parteners from "../components/Layout/Parteners.vue";
import ServiceCard from "../components/Layout/ServiceCard.vue";
// import Testimonials from "../components/Layout/Testimonials.vue";
import PageHeader from "../components/misc/PageHeader.vue";
import Modal from "../components/Layout/Modal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PageHeader,
    Counts,
    ServiceCard,
    Parteners,
    // Testimonials,
    // OurTeam,
    ContactUs,
    // LetUsHelp,
    Modal,
  },
  data() {
    return {
      modalData: null,
      link: null,
      btnName: null,
      activeModal: false,
    };
  },
  methods: {
    toggelModal(ser) {
      this.activeModal = !this.activeModal;
      if (ser) {
        this.modalData = ser;
        this.btnName = "See Use Cases";
        this.link = {
          name: "https://google.com",
        };
      }
    },
  },
  computed: {
    ...mapGetters(["services"]),
  },
};
</script>

<style>
</style>