<template>
  <div
    class="contact-us-sec"
    :style="`background-image: url('${require('../../assets/images/contact-cover.png')}')`"
  >
    <div class="container d-flex justify-content-center align-items-center">
      <div class="box">
        <div class="sec-title">
          {{ $t("freeConsAsk") }}
        </div>
        <p class="sec-desc">
          {{ $t("freeConsAskDesc") }}
        </p>
        <router-link :to="{ name: 'ContactUs' }">
          <button class="btn-pri-dark">{{ $t("bookFreeCons") }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>